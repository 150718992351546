body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.video_output{
  width: 400px;
  height: 700px;
}
.background{
  background-color: #BEBEBE;
  width: 100%;
  height: 100%;
  /* height: 600px; */
  margin: auto;
  background-image: url("./img/bg/PC_BG.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* border: solid 2px  rgb(21, 15, 15);
  border-radius: 25px; */

  min-height: 100%;

	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

}

.head2{
  background-color: #FFD700;
  color:#696969;
  width: 340px;
  height: 35px;
  border: solid 2px  #FFD700;
  border-radius: 10px;
  padding-top: 8px;
  align-items: center;
}

.headText1{
  color: #FFF;
  font-size: 80px;
}
.headText2{
  color: #FFF;
  font-size: 54px;
}
.divUploadImg{

  height: 100%;

}
.divLoading{

  height: 100%;

}
.upImgDog{
  background-image: url("./img/1_upload/upload_DOG.png");
  height: 240px;
  width: 260px;
  /* border: solid 2px  #696969; */
  border-radius: 25px;
  display:inline-block;
  margin: 2%;
  background-repeat: no-repeat;
  background-size: cover;
}

.upImgCat{
  background-image: url("./img/1_upload/upload_CAT.png");
  height: 240px;
  width: 260px;
  /* border: solid 2px  #696969; */
  border-radius: 25px;
  display:inline-block;
  margin: 2%;
  background-repeat: no-repeat;
  background-size: cover;
}
.editButton{
  bottom: 10px;
  margin-top:90%;
  background-color: #ff3a89;
  /* position: absolute; */
  border: solid 2px  #ff3a89;
  border-radius: 20px;
  height: 40px;
  width: 80px;
  font-family: Kanit;
  font-size: 17px;
  color: #FFF;
}
.Submit{
  background-color: #ff3a89;
  /* position: absolute; */
  border: solid 2px  #ff3a89;
  border-radius: 10px;
  height: 70px;
  width: 150px;
  font-family: Kanit;
  font-size: 30px;
  color: #FFF;
}
.imgDog{
  width: 200px;
  height: 200px;
  background-image: url("./img/1_upload/dog_upload.png");
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
}
.imgCat{
  width: 200px;
  height: 200px;
  background-image: url("./img/1_upload/cat_upload.png");
  background-repeat: no-repeat;
  background-size: 90%;
  float: right;
  background-position: center;
}
.marginHeader{
  line-height:36px;
  padding-top:5%;
}

.footer{
  margin-top: 3%;
}
.checkbox{
  
  color:#ff3a89;
  font-size: 16px;
  margin-top: 1%;
  width: 100%;
  text-align: center;

}

/* video */
.headVideo1{
  color: #FFF;
  font-size: 38px;
}
.headVideo2{
  color: #FFF;
  font-size: 80px;
}
.divVideo1{
 display: inline-block;
  width: 400px;
  height: 200px;
  float:right;
  text-align: center;
 
  
}
.divUploadVideo{
  width: 400px;
  height: 700px;
  /* background-color: #696969; */
  display: inline-block;
  float: left;
}
.backgroundVideo{
  background-color: #BEBEBE;
  width: 100%;
  height: 100%;
  /* height: 600px; */
  margin: auto;
  background-image: url("./img/bg/PC_BG.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* border: solid 2px  rgb(21, 15, 15);
  border-radius: 25px; */
  min-height: 100%;
  overflow: scroll;

	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
	
  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;

}
.boxVideo{
  height: 100%;
  width: 850px;
  margin: auto;
  padding-top: 4%;
  overflow: scroll;
  
}
.marginHeadeVideo{
  line-height:50px;
  padding-top:5%;
}

.buttomDownload{
  height: 60px;
  width: 220px;
  margin: 1%;
  border-radius: 15px;
  background-image: url("./img/2_download/download2.png");
  background-repeat: no-repeat;
    background-size: cover;
}
.buttomShare{
  height: 60px;
  width: 220px;
  margin: 1%;
  border-radius: 15px;
  background-image: url("./img/2_download/share2.png");
  background-repeat: no-repeat;
    background-size: cover;
}

.ButtonBox{
  display: inline-block;
  /* background-color: aqua; */
  width: 450px;
  height: 450px;
 padding-left: 5%;
  padding-top:  14%;
  
}
.boxPetsImg{
  /* background-color: #696969; */
  height: 200px;
  margin-top: 7%;
  
}
.V_ImgDog{
  height: 140px;
  background-image: url("./img/2_download/dog_download.png");
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 70px;
}
.V_ImgCat{
  height: 140px;
  margin-top: "50px";
  background-image: url("./img/2_download/cat_download.png");
  background-repeat: no-repeat;
  background-size: 90%;
  margin-top: 67px;
  
}
/* video */









/* iped mini */
@media screen and (min-width:768px) and (max-width:800px)  {

  .marginHeader{
    
    padding-top:15%;
  }
  .upImgDog{
    background-image: url("./img/1_upload/upload_DOG.png");
    height: 300px;
    width: 320px;
    /* border: solid 2px  #696969; */
    border-radius: 25px;
    display:inline-block;
    margin: 2%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .upImgCat{
    background-image: url("./img/1_upload/upload_CAT.png");
    height: 300px;
    width: 320px;
    /* border: solid 2px  #696969; */
    border-radius: 25px;
    display:inline-block;
    margin: 2%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .background{
    background-image: url("./img/bg/TABLET_BG.png");
  }
  .checkbox{
    margin-top: 10%;
  

  }
  .marginHeadeVideo{
   width: 100%;
    padding-top:0%;
    
  }
  .divUploadVideo{
    width: 400px;
    height: 700px;
    background-color: #696969;
    float: left;
    display: inline-block;
    margin-left: 2%;
   
   
  }
  .divVideo1{
    
     width: 100%;
     height: 200px;
     
     
   }
   .ButtonBox{
    display: inline-block;
    /* background-color: aqua; */
    width: 400px;
    height: 450px;
    padding-right: 11%;
    padding-top:  25%;
    
  }
}


/* iped Air */
@media screen and (min-width:820px) and (max-width:1368px)  {

  /* .marginHeader{
    
    padding-top:15%;
  } */
  .upImgDog{
    background-image: url("./img/1_upload/upload_DOG.png");
    height: 300px;
    width: 320px;
    /* border: solid 2px  #696969; */
    border-radius: 25px;
    display:inline-block;
    margin: 2%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .upImgCat{
    background-image: url("./img/1_upload/upload_CAT.png");
    height: 300px;
    width: 320px;
    /* border: solid 2px  #696969; */
    border-radius: 25px;
    display:inline-block;
    margin: 2%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgDog{
    width: 200px;
    height: 900%;
  
  }
  .imgCat{
    width: 200px;
    height: 900%;
  
  }
  .background{
    background-image: url("./img/bg/TABLET_BG.png");
    overflow: auto;
  }
  .checkbox{
    margin-top: 10%;
  }
  .ButtonBox{
    display: inline-block;
    /* background-color: aqua; */
    width: 400px;
    height: 450px;
    padding-top:  25%;
    
  }
  .marginHeadeVideo{
    width: 100%;
     padding-top:0%;
     
   }
   .divUploadVideo{
     width: 400px;
     height: 700px;
     background-color: #696969;
     float: left;
     display: inline-block;
     margin-left: 2%;
    
    
   }
   .divVideo1{
     
      width: 100%;
      height: 200px;
      
      
    }
    .divLoading{
      margin-top: 25%;
      height: 100%;
    
    }
}


@media screen and (min-width:337px) and (max-width:500px)  {
  .divLoading{
    margin-top: 25%;
    height: 100%;
  
  }
  .marginHeader{
    
    padding-top:15%;
  }
  .upImg{
    /* background-image: url("./img/1_upload/upload_text.png"); */
    height: 300px;
    width: 320px;
    /* border: solid 2px  #696969; */
    border-radius: 25px;
    display:inline-block;
    margin: 2%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgDog{
    width: 120px;
    height: 300%;
  
  }
  .imgCat{
    width: 120px;
    height: 300%;
  
  }
  .background{
    background-image: url("./img/bg/MOBILE_BG.png");
    overflow: scroll;
  }
  .upImgDog{
    background-image: url("./img/1_upload/upload_DOG.png");
    height: 210px;
    width: 220px;
    /* border: solid 2px  #696969; */
    border-radius: 25px;
    display:inline-block;
    margin: 2%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .upImgCat{
    background-image: url("./img/1_upload/upload_CAT.png");
    height: 210px;
    width: 220px;
    /* border: solid 2px  #696969; */
    border-radius: 25px;
    display:inline-block;
    margin: 2%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .editButton{
    margin-top:93%;
    font-size: 14px;
    height: 35px;
    width: 70px;
  }
  .headText1{
    font-size: 60px;
  }
  .headText2{
    font-size: 44px;
  }
  .Submit{
    width: 100px;
    height: 50px;
    font-size: 18px;
  }

  .marginHeader{

    padding-top:5%;
  }
  .checkbox{
    margin-top: 10%;
  }


  .divVideo1{
    
    width: 100%;
    height: 160px;
    
    
  }

  .headVideo1{
    color: #FFF;
    font-size: 28px;
  }
  .headVideo2{
    color: #FFF;
    font-size: 60px;
  }

.divUploadVideo{
  width: 100%;
  height: 440px;
  align-items: center;
  text-align: center;
  
}
.video_output{
  width: 60%;
  height: 440px;
  
}

.boxVideo{

  width: 100%;
  padding-top: 3%;
  overflow: scroll;
}
.marginHeadeVideo{
  line-height:36px;
  padding-top:5%;
}
.ButtonBox{
  display: inline-block;
  /* background-color: aqua; */
  width: 100%;
  height: 100px;
  padding-top:  2%;
  padding-left: 0;
  
}
.buttomDownload{
  height: 40px;
  width: 140px;
  margin: 1%;
  border-radius: 15px;
  background-image: url("./img/2_download/download2.png");
  background-repeat: no-repeat;
    background-size: cover;
}
.buttomShare{
  height: 40px;
  width: 140px;
  margin: 1%;
  border-radius: 15px;
  background-image: url("./img/2_download/share2.png");
  background-repeat: no-repeat;
    background-size: cover;
}
.boxPetsImg{
  /* background-color: #696969; */
  height: 100px;
  margin-top: 0;
  
}
.V_ImgDog{

  background-image: url("./img/2_download/dog_download.png");
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 1px;
  width: 70%;
  float: right;
}
.V_ImgCat{

  margin-top: "50px";
  background-image: url("./img/2_download/cat_download.png");
  background-repeat: no-repeat;
  background-size: 70%;
  margin-top: 1px;
  width: 90%;
  
}
.backgroundVideo{
  height: 100%;
  overflow: scroll;
}


}

@media screen and (min-width:337px) and (max-width:376px)  {

  .marginHeader{
    
    padding-top:15%;
  }

  .imgDog{
    width: 120px;
    height: 300%;
  
  }
  .imgCat{
    width: 120px;
    height: 300%;
  
  }
  .background{
    background-image: url("./img/bg/MOBILE_BG.png");
    overflow: scroll;
 
   
  }
  .upImgDog{
    background-image: url("./img/1_upload/upload_DOG.png");
    height: 210px;
    width: 220px;
    /* border: solid 2px  #696969; */
    border-radius: 25px;
    display:inline-block;
    margin: 2%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .upImgCat{
    background-image: url("./img/1_upload/upload_CAT.png");
    height: 210px;
    width: 220px;
    /* border: solid 2px  #696969; */
    border-radius: 25px;
    display:inline-block;
    margin: 2%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .editButton{
    margin-top:93%;
    font-size: 14px;
    height: 35px;
    width: 70px;
  }
  .headText1{
    font-size: 60px;
  }
  .headText2{
    font-size: 44px;
  }
  .Submit{
    width: 100px;
    height: 50px;
    font-size: 18px;
  }

  .marginHeader{

    padding-top:5%;
  }
  .checkbox{
    margin-top: 10%;
  }
  .footer{
    height: 0px;
  }
  .boxVideo{
    
    overflow: scroll;
  }
  .backgroundVideo{
    height: 100%;
    overflow: scroll;
  }

}